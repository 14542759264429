import { Form, Formik } from 'formik';
import { PayRentTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/account/PayRentTypes';
import { getExistingCardPaymentInitValues } from '@codeparva/gatsby-theme-layout-components/src/sections/account/helpers/cardDetailsFormSchema';
import { ExistingCardTypeIcon } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/PayRent/CardTypeIcon';
import { PaymentField } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/PayRent/PaymentField';
import React, { useState, useRef } from 'react';
import { PayRentPaymentErrorScreen } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/ErrorScreens/PaymentErrorScreen';
import {
  CARD_PROCESSOR_TYPE,
  CARD_PROCESSOR_SHOW_ON_PAYMENT,
  CARD_PROCESSOR_STATUS,
} from '@codeparva/gatsby-theme-layout-components/src/components/syrapayHostedForm/Constants';
import { format } from 'date-fns';
import { Icon } from '@codeparva/gatsby-theme-layout-components/src/utilities/icomoon-css';
import AutoPayCheckbox from './AutoPayCheckbox';
import { getLedgerBalance } from '@codeparva/gatsby-theme-layout-components/src/sections/rentalPage/helpers/paymentComponentHelper';

export const ExistingCardDetails = ({
  cardType,
  card_info,
  hidePayments,
  callBackHandler,
  cardProcessorIds,
  is_auto_pay_enabled,
  billingDetailsConfig,
  isAutoPayOptInEnabled,
  isAutoPayOptOutEnabled,
  disablePayment = false,
  preferred_payment_method_id,
  chargeDetails = {},
}: PayRentTypes.TExistingCardInfoConfig) => {
  const [hasError, setHasError] = useState(false);
  const formRef = useRef<any>(null);
  const {
    expiration_date: { month = '', year = '' } = {},
    masked_card_number,
  } = card_info ?? {};

  const cardProcessorId = cardProcessorIds.data.filter(
    (indivMethod: any) =>
      indivMethod.value.processor_type === CARD_PROCESSOR_TYPE &&
      indivMethod.value.status === CARD_PROCESSOR_STATUS &&
      indivMethod.value.show_on.includes(CARD_PROCESSOR_SHOW_ON_PAYMENT)
  )[0]?.id;

  const { current_balance_due = 0, new_balance } = billingDetailsConfig ?? {};

  const getExistingCardDetailsRequest = () => {
    const getTodaysDate = format(new Date(), 'yyyy-MM-dd');
    return {
      amount: new_balance || getLedgerBalance(current_balance_due) || 0, // NOTE: This will change since payment request has been changed
      use_card_on_file: true,
      payment_method_id: preferred_payment_method_id,
      effective_date: getTodaysDate,
      card_info: {},
      card_processor_id: cardProcessorId,
      auto_pay_enabled: formRef.current.values.autoPay,
      amount_without_modifier: chargeDetails.amount_without_modifer,
      surcharge_or_discount: chargeDetails.charge_amount,
    };
  };

  return (
    <>
      {hasError ? (
        <PayRentPaymentErrorScreen setError={setHasError} />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={getExistingCardPaymentInitValues(
            isAutoPayOptInEnabled
          )}
          onSubmit={async (_values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            const requestBody = getExistingCardDetailsRequest();
            try {
              await callBackHandler(requestBody);
            } catch (e) {
              console.error('Error from new card section', e);
              setHasError(true);
              resetForm();
            }
          }}
        >
          {({ handleSubmit, values: { autoPay } = {} }) => {
            return (
              <Form method="post" autoComplete="off" onSubmit={handleSubmit}>
                <div className="savedCardInfoCard">
                  <div className="cardContent">
                    <div className="cardTypeIcon">
                      {cardType && <ExistingCardTypeIcon cardType={cardType} />}
                      <div className="maskedCardNumber">
                        {masked_card_number}
                      </div>
                      <div className="cardExpiryNumber">
                        Expiry : {`${month}/${year}`}
                      </div>
                    </div>
                  </div>
                  <Icon
                    className="tfw-tick-circle-2-cp"
                    name="tfw-tick-circle-2-cp"
                  />
                </div>
                {!is_auto_pay_enabled && (
                  <AutoPayCheckbox
                    isAutoPayOptInEnabled={isAutoPayOptInEnabled}
                    isAutoPayOptOutEnabled={isAutoPayOptOutEnabled}
                  />
                )}
                <PaymentField
                  shouldShowHostedForm={false}
                  disableBtn={disablePayment}
                  hidePaymentDetails={hidePayments}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
