import { CustomCheckBoxField } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/FormFields/GeneralFormFields';
import React from 'react';

const AutoPayCheckbox = ({
  isAutoPayOptInEnabled,
  isAutoPayOptOutEnabled,
}: any) => {
  return (
    isAutoPayOptInEnabled && (
      <CustomCheckBoxField
        label="SignUp for autopay"
        name="autoPay"
        disabled={!isAutoPayOptOutEnabled}
      />
    )
  );
};

export default AutoPayCheckbox;
