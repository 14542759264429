import { newFormikInitialValues } from '@codeparva/gatsby-theme-layout-components/src/sections/account/helpers/cardDetailsFormSchema';
import { Button, Column, Container } from '@codeparva/ui-components-library';
import { AccountPanelTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/account/AccountPanelTypes';
import { BillingDetails } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/PayRent/BillingDetails';
import { PaymentField } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/PayRent/PaymentField';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  OtherPaymentErrorScreen,
  PayRentPaymentErrorScreen,
} from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/ErrorScreens/PaymentErrorScreen';
import { CardDetailsContext } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/context/newDetailsCardContext';
import {
  CARD_PROCESSOR_SHOW_ON_PAYMENT,
  CARD_PROCESSOR_STATUS,
  CARD_PROCESSOR_TYPE,
  HOSTED_FORM_ID,
} from '@codeparva/gatsby-theme-layout-components/src/components/syrapayHostedForm/Constants';
import { CustomCheckBoxField } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/FormFields/GeneralFormFields';
import SyrapayHostedForm from '@codeparva/gatsby-theme-layout-components/src/components/syrapayHostedForm/SyrapayHostedForm';
import { fetchCardProcessorAuthKey } from '@codeparva/gatsby-theme-layout-components/src/sections/account/helpers/apiHelpers';
import { PaymentContext } from '@codeparva/gatsby-theme-layout-components/src/components/syrapayHostedForm/context/PaymentContext';
import {
  getPaymentMethodInfo,
  getPaymentRequest,
} from '@codeparva/gatsby-theme-layout-components/src/sections/account/helpers/PaymentHelpers';
import { format } from 'date-fns';
import {
  removeMoveInReserveCookie,
  setMoveInReserveCookie,
} from '@codeparva/gatsby-theme-layout-components/src/sections/rentalPage/components/dynamicForm/helper/cookiesHelper';
import {
  getAmountToPay,
  getUpdatedAmount,
} from '@codeparva/gatsby-theme-layout-components/src/sections/rentalPage/helpers/paymentComponentHelper';
import { weakKey } from '@codeparva/gatsby-theme-layout-components/src/utilities/weakKey';
import { isObjWithKeys } from '@codeparva/gatsby-theme-layout-components/src/utilities/objectHelper';
import AutoPayCheckbox from './AutoPayCheckbox';
import {
  amountResolutionType,
  paymentMethodType,
} from '@codeparva/gatsby-theme-layout-components/src/sections/account/constants/accountPanelConfigs';
import { ACHFormModal } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/Form/ACHFormModal';
import { PaymentTypeSelector } from '@codeparva/gatsby-theme-layout-components/src/sections/account/components/PayRent/PaymentMethodTypeSelector';

export const NewCardSection = ({
  configs,
  facilityId,
  contextData,
  customAmount,
  payment_method,
  callBackHandler,
  setCustomAmount,
  amountResolution,
  cardProcessorIds,
  customAmountError,
  individualUnitData,
  setAmountResolution,
  billingDetailsConfig,
  tenantIdFromCreationAPI,
  parentChargeDetailsUpdater,
  tfwPaymentConfig: {
    is_autopay_opt_in_enabled: isAutoPayOptInEnabled = false,
    is_autopay_opt_out_enabled: isAutoPayOptOutEnabled = false,
    is_partial_payment_enabled: isPartialPaymentAllowed = false,
    is_pre_payment_enabled: isPrepaymentAllowed = false,
    is_ach_payment_enabled: isAchPaymentEnabled = false,
  } = {},
  preferred_payment_method_id,
  paymentType,
  setPaymentType,
  public_key,
  isLoadingPaymentInfo,
  tenantToken,
}: AccountPanelTypes.TNewCardSection) => {
  const [disabled, setDisabled] = React.useState(false);
  const [hasError, setHasError] = useState(false);
  const [authKey, setAuthKey] = useState('');
  const [amount, setAmount] = useState(
    configs?.total_amount || billingDetailsConfig?.current_balance_due
  );
  const [amountWithoutModifier, setAmountWithoutModifier] = useState(
    configs?.base_amount || amount
  );
  const [surchargeOrDiscount, setSurchargeOrDiscount] = useState(
    configs?.surcharge_or_discount || 0
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('default');
  const [shouldShowHostedForm, setShouldShowHostedForm] = useState(false);
  const [shouldShowAchForm, setShouldShowAchForm] = useState(false);
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);
  const formRef = useRef<any>(null);
  const currentSelectedMethod = useRef<any>();
  const paymentMethodAmount = useRef<any>();
  const paymentContext = useContext(PaymentContext);
  if (configs) {
    configs.facilityId = facilityId;
  }
  const notUsingInPayRent = configs ? false : true;
  const { total_amount = '' } = configs ?? {};
  const copyBillingDetails = {
    ...billingDetailsConfig,
    ...(isObjWithKeys(configs) && { new_balance: amount }),
    isPrepaymentAllowed,
    isPartialPaymentAllowed,
    facilityId,
  };

  const { new_balance } = copyBillingDetails;

  useEffect(() => {
    if (selectedPaymentMethod === 'default' && configs) {
      setDisablePaymentButton(true);
    }
  }, []);

  useEffect(() => {
    if (selectedPaymentMethod === 'default') {
      setDisablePaymentButton(true);
    } else {
      setDisablePaymentButton(false);
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setSelectedPaymentMethod('default');
  }, [amountResolution]);

  useEffect(() => {
    if (selectedPaymentMethod !== 'default') {
      setSelectedPaymentMethod('default');
      // TODO: Set original amount
    }
  }, [paymentType]);

  const cardProcessorId = cardProcessorIds.data.filter(
    (indivMethod: any) =>
      indivMethod.value.processor_type === CARD_PROCESSOR_TYPE &&
      indivMethod.value.status === CARD_PROCESSOR_STATUS &&
      indivMethod.value.show_on.includes(CARD_PROCESSOR_SHOW_ON_PAYMENT)
  )[0]?.id;

  const { current_balance_due = 0 } = billingDetailsConfig ?? {};
  let hidePayments = false;
  if (Number(current_balance_due) < 0 && !isPrepaymentAllowed) {
    hidePayments = true;
  } else if (total_amount) {
    hidePayments = true;
  }
  const isPaymentDisabled = () => {
    const newBal = customAmount || new_balance;
    if (customAmountError?.current) return true;
    if (amountResolution === amountResolutionType.balanceAmount) {
      return Number(current_balance_due) <= 0;
    }
    // For custom amount
    return (
      Number(
        getAmountToPay({ current_balance_due, new_balance: newBal }, true)
      ) <= 0
    );
  };
  const handleTryAgain = async () => {
    // Need to make a call to get card authorization again
    setShouldShowHostedForm(false);
    await fetchCardProcessor();
    setHasError(false);
  };
  const showErrorScreen = () => {
    if (notUsingInPayRent) {
      return (
        <PayRentPaymentErrorScreen
          setError={setHasError}
          onTryAgain={handleTryAgain}
        />
      );
    } else {
      const otherConfigData = {
        ...contextData,
        facilityId,
        tenantIdFromCreationAPI,
        configs,
      };
      return (
        <OtherPaymentErrorScreen
          setError={setHasError}
          otherConfigData={otherConfigData}
          onTryAgain={handleTryAgain}
        />
      );
    }
  };

  const getPaymentAmount = () => {
    // let finalAmount;
    // if (notUsingInPayRent && amount === amountWithoutModifer) {
    //   finalAmount = customAmount ? customAmount : current_balance_due;
    // } else {
    // finalAmount = amount;
    // }
    return amount;
  };

  const savingForFutureTransaction = formRef.current
    ? formRef.current.values.saveCardDetails
    : false;

  const getPaymentMethod = () =>
    selectedPaymentMethod !== 'default'
      ? selectedPaymentMethod
      : configs.selectedPaymentId;
  const getAutoPayStatus = () =>
    formRef.current ? formRef.current.values.autoPay : false;

  const hostedFormSubmitHandler = async (
    transaction_id: any,
    token_to_save: any,
    otherResponse: any
  ) => {
    const {
      gatewayResponse: {
        accountDetails: { cardBIN },
      },
      requestDetails: {
        expirationMonth,
        expirationYear,
        address1,
        zip,
        nameOnAccount,
      },
    } = otherResponse;
    console.log('cardBIN', cardBIN);
    // const cardType = cc_brand_id(cardBIN);
    const todaysDate = format(new Date(), 'yyyy-MM-dd');
    const paymentAmount = getPaymentAmount();

    // const { data: paymentMethodResponse = [] } = payment_method ?? {};

    const requestBody = getPaymentRequest(
      paymentAmount,
      getAutoPayStatus(),
      amountWithoutModifier,
      individualUnitData || {},
      getPaymentMethod(),
      paymentType,
      savingForFutureTransaction,
      surchargeOrDiscount,
      todaysDate,
      {
        address1,
        nameOnAccount,
        cardProcessorId,
        expMonth: expirationMonth.toString(),
        expYear: expirationYear
          .toString()
          .slice(
            expirationYear.toString().length - 2,
            expirationYear.toString().length
          ),
        zip,
        token_to_save,
        transaction_id,
      }
    );

    if (contextData) {
      requestBody.tenantUserName = contextData.tenantUserName;
      requestBody.tenantPassword = contextData.tenantUserPassword;
      requestBody.tenantId = contextData.tenantId;
      requestBody.tenantIdFromCreationAPI = tenantIdFromCreationAPI ?? '';
      requestBody.individualUnitData = individualUnitData;
    }
    try {
      removeMoveInReserveCookie();
      await callBackHandler(requestBody, configs);
    } catch (e) {
      setMoveInReserveCookie();
      console.error('Error from new card section', e);
      setHasError(true);
      setDisabled(false);
    }
  };

  const paymentCancellationHandler = () => {
    setShouldShowHostedForm(false);
    setDisabled(false);
  };

  const reCalculateTheFinalRate = async (selectedPaymentMethod: any) => {
    const facility_ID = facilityId || process.env.GATSBY_FACILITY_ID;
    const payment_method_modifier = getPaymentMethodInfo(
      selectedPaymentMethod,
      'modifier'
    );
    const {
      amount: updatedAmount,
      surcharge_or_discount,
      amount_without_modifier,
    } = await getUpdatedAmount(
      getPaymentMethodInfo(selectedPaymentMethod, 'id'),
      parseFloat(
        configs?.total_amount ||
          customAmount ||
          billingDetailsConfig?.current_balance_due
      ),
      facility_ID || '',
      tenantToken
    );
    paymentMethodAmount.current = surcharge_or_discount;
    currentSelectedMethod.current = payment_method_modifier;
    if (parentChargeDetailsUpdater) {
      parentChargeDetailsUpdater({
        chargeType: payment_method_modifier,
        charge_amount: surcharge_or_discount,
        amount: updatedAmount,
      });
    }
    setAmount(updatedAmount);
    setSurchargeOrDiscount(surcharge_or_discount);
    setAmountWithoutModifier(amount_without_modifier);
  };

  const handleChange = (e: any) => {
    const { target: { value: selectedValue = null } = {} } = e || {};
    const filteredPaymentMethod = payment_method.data.filter(
      (eachPaymentMethod: any) => eachPaymentMethod.value.modifier
    );
    const selectedPaymentMethod = filteredPaymentMethod.find(
      (item: any) => item.id === selectedValue
    );

    if (selectedValue !== 'default') {
      setSelectedPaymentMethod(
        getPaymentMethodInfo(selectedPaymentMethod, 'id')
      );
    }

    if (selectedPaymentMethod) {
      reCalculateTheFinalRate(selectedPaymentMethod);
    }
  };

  const fetchCardProcessor = async () => {
    const [
      ,
      { data: { data = {} } = {} } = {},
    ] = await fetchCardProcessorAuthKey(
      total_amount ||
        getAmountToPay({
          current_balance_due,
          new_balance: new_balance || amount,
        }),
      cardProcessorId,
      facilityId,
      tenantToken
    );
    setAuthKey(data.authenticationKey);
    paymentContext?.setAuthenticationKey(data.authenticationKey);
  };
  const getPaymentMethodOptions = (paymentMethod: any, paymentType: any) => {
    return paymentMethod.data.map((eachPaymentMethod: any) => {
      const payment_method_modifier = getPaymentMethodInfo(
        eachPaymentMethod,
        'modifier'
      );
      const paymentMethodId = getPaymentMethodInfo(eachPaymentMethod, 'id');
      const modifierType =
        payment_method_modifier !== 'No_Modifier'
          ? `-${payment_method_modifier}`
          : '';

      return (
        eachPaymentMethod.value.type === paymentType &&
        eachPaymentMethod.value.status !== 'Inactive' && (
          <option
            value={paymentMethodId}
            key={weakKey({ id: paymentMethodId })}
          >
            {eachPaymentMethod.value.description}
            {modifierType}
          </option>
        )
      );
    });
  };

  const PaymentMethodSelectionDropDown = () => {
    return (
      <div
        className="select is-large is-primary"
        style={{
          marginBottom: '2rem',
          marginTop: '2rem',
        }}
      >
        <select
          value={selectedPaymentMethod}
          onChange={handleChange}
          disabled={isPaymentDisabled()}
        >
          <option value="default" disabled>
            Select a Payment Method
          </option>
          {getPaymentMethodOptions(payment_method, paymentType)}
        </select>
      </div>
    );
  };

  return (
    <React.Fragment data-testid="new-card-section">
      <CardDetailsContext.Provider
        value={{
          payment_method,
        }}
      >
        {hasError ? (
          showErrorScreen()
        ) : (
          <Formik
            innerRef={formRef}
            initialValues={{
              ...newFormikInitialValues,
              autoPay: isAutoPayOptInEnabled,
              saveCardDetails: isAutoPayOptInEnabled,
            }}
            onSubmit={async (_values, { setSubmitting }) => {
              setDisabled(true);
              setSubmitting(false);
              if (
                isAchPaymentEnabled &&
                paymentType === paymentMethodType.ach
              ) {
                setShouldShowAchForm(true);
              } else {
                await fetchCardProcessor();
                setShouldShowHostedForm(true);
              }
            }}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form
                  method="post"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  id={HOSTED_FORM_ID}
                >
                  <Container
                    marginless
                    className={`newCardParentSection py-4 ${
                      !notUsingInPayRent ? 'move_in_reserve' : ''
                    }`}
                  >
                    <Column className="payRentContainer">
                      <Button
                        id="payment-button"
                        type="button"
                        onClick={(e: any) => {
                          handleSubmit(e);
                        }}
                        disabled={isLoadingPaymentInfo || disabled}
                        color="primary"
                        className={`buttonForPayment ${
                          shouldShowHostedForm || isLoadingPaymentInfo
                            ? 'is-loading'
                            : ''
                        }`}
                        fullwidth={true}
                      >
                        {'Pay '} ${total_amount}
                      </Button>
                    </Column>
                    <div className="contentContainer">
                      <Column className="billingDetailsContainer">
                        <BillingDetails
                          {...copyBillingDetails}
                          facility_id={facilityId}
                          customAmount={customAmount}
                          setCustomAmount={setCustomAmount}
                          amountResolution={amountResolution}
                          customAmountError={customAmountError}
                          setAmountResolution={setAmountResolution}
                          parentChargeDetailsUpdater={
                            parentChargeDetailsUpdater
                          }
                          preferred_payment_method_id={
                            preferred_payment_method_id
                          }
                          tenantToken={tenantToken}
                        />
                        {isAchPaymentEnabled && (
                          <PaymentTypeSelector
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                          />
                        )}
                        <PaymentMethodSelectionDropDown />
                        {!hidePayments && (
                          <div className="checkBoxSection">
                            <CustomCheckBoxField
                              label="Save card details for future payments"
                              name="saveCardDetails"
                              checked={
                                values.autoPay || values.saveCardDetails
                                  ? true
                                  : false
                              }
                              disabled={values.autoPay ? true : false}
                            />
                            <AutoPayCheckbox
                              isAutoPayOptInEnabled={isAutoPayOptInEnabled}
                              isAutoPayOptOutEnabled={isAutoPayOptOutEnabled}
                            />
                          </div>
                        )}
                      </Column>
                      {Number(
                        getAmountToPay(
                          {
                            current_balance_due,
                            new_balance: amount || new_balance,
                          },
                          true
                        )
                      ) > 0 ? (
                        <PaymentField
                          shouldShowHostedForm={shouldShowHostedForm}
                          hidePaymentDetails={hidePayments}
                          disableBtn={
                            disablePaymentButton || isPaymentDisabled()
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Container>
                  {shouldShowAchForm && (
                    <ACHFormModal
                      shouldShowAchForm={shouldShowAchForm}
                      setShouldShowAchForm={setShouldShowAchForm}
                      getPaymentAmount={getPaymentAmount}
                      callBackHandler={callBackHandler}
                      autoPayStatues={getAutoPayStatus()}
                      amountWithoutModifier={amountWithoutModifier}
                      individualUnitData={individualUnitData}
                      paymentMethod={getPaymentMethod()}
                      paymentType={paymentType}
                      savingForFutureTransaction={savingForFutureTransaction}
                      surchargeOrDiscount={surchargeOrDiscount}
                      removeMoveInReserveCookie={removeMoveInReserveCookie}
                      configs={configs}
                      setMoveInReserveCookie={setMoveInReserveCookie}
                      setHasError={setHasError}
                      setDisabled={setDisabled}
                      publicKey={public_key}
                    />
                  )}
                  {shouldShowHostedForm ? (
                    <SyrapayHostedForm
                      amount={amount || total_amount || current_balance_due}
                      shouldSaveCard={values.saveCardDetails || values.autoPay}
                      authKey={authKey}
                      hostedFormSubmitHandler={hostedFormSubmitHandler}
                      paymentCancellationHandler={paymentCancellationHandler}
                    />
                  ) : (
                    <></>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </CardDetailsContext.Provider>
    </React.Fragment>
  );
};
